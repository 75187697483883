import React, { useState } from 'react';
import { Loader as PlaybookLoader } from '@stats/playbook-components';
import styled from 'styled-components';

const LoaderWrapper = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  height: 300px;
`;

const Loader: React.FC<{}> = () => {
  const [displayLoader, setDisplayLoader] = useState(false);

  setTimeout(() => setDisplayLoader(true), 500);

  return (
    <LoaderWrapper>
      {displayLoader && <PlaybookLoader />}
    </LoaderWrapper>
  );
};

export default Loader;