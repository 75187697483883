import React, { useContext } from 'react';
import { useParams, NavLink, useRouteMatch } from 'react-router-dom';
import { withAuth0, WithAuth0Props } from '@auth0/auth0-react';

import DeliveriesContext from '@/context/DeliveriesContext';
import { ErrorPage } from '@/pages/ErrorPage';

import Loader from '@components/Loader';
import Table from '@components/Table';
import LinkButton from '@components/Button';

import { ItemsProps, TableItemProps } from '@/propTypes';

const normalizePath = (path: string): string => path? path.replace(/\/$/, '') : '';

function formatBytes(bytes: number | undefined, decimals = 2) {
  if (!bytes) return 'N/A';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const dateRender = (datetime: string) => {
  const format = new Intl.DateTimeFormat('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  });

  return format.format(new Date(datetime));
};

const FileView: React.FC<WithAuth0Props> = (props) => {
  const { user } = props.auth0;

  const { url } = useRouteMatch();
  const { deliveryDir } = useParams<{ deliveryDir: string; }>();
  const delivery = useContext(DeliveriesContext);

  if (!user) {
    return (
      < ErrorPage />
    )
  }

  const { deliveryName } = user["https://statsperform.com/distributionServices"];

  const normalizedDir = normalizePath(deliveryDir);
  const dirDepth = normalizedDir.length > 0 
    ? normalizedDir.split('/').length
    : 0;

  // Placeholders
  const fileDirectories: Array<any> = [];
  const items: TableItemProps  = [];
  const columns: any[] = [];
    
  // Sets back button
  if (dirDepth) {
    items.push({
      name: '../',
      type: 'dir',
      url: url.replace(/\/[^/]+\/?$/, '')
    });
  }

  // Set Items to display
  delivery.files.forEach((file: any, ind: number) => {
    
    const { 
      directory
    } = file;
    const normalizedFileDir = normalizePath(directory);
    const fileDirDepth = normalizedFileDir.length > 0
      ? normalizedFileDir.split('/').length
      : 0;

    if( normalizedFileDir.indexOf(normalizedDir) === 0 ) {

      // Add File to items
      if( dirDepth === fileDirDepth && normalizedFileDir === normalizedDir ) {
        return items.push({
          name: file.fileName,
          type: 'file',
          ...file
        });
      }

      // Add directory to items
      if( dirDepth < fileDirDepth ) {

        const filePathArr = normalizedFileDir.split('/');
        const newDirectory = filePathArr[dirDepth];

        // Make sure that we haven't already added this directory
        if( !fileDirectories.includes(newDirectory) ) {
          fileDirectories.push(newDirectory);
          return items.push({
            name: `${newDirectory}/`,
            type: 'dir',
            url: `${normalizePath(url)}/${newDirectory}/`
          })
        }

      }
    }

  });

  // If there is at least 1 file in the directory, generate a FILE_LIST.XML and FILE_LIST_RECENT.XML
  if (items.some((item) => item.type === 'file')) {
    items.unshift({
      name: 'FILE_LIST.XML',
      directory: (deliveryDir) ? deliveryDir + "/" : undefined,
      type: 'file',
      fileName: 'FILE_LIST.XML',
      size: 0,
      lastUpdatedAt: (new Date()).toUTCString(),
      url: '',
    }, {
      name: 'FILE_LIST_RECENT.XML',
      directory: (deliveryDir) ? deliveryDir + "/" : undefined,
      type: 'file',
      fileName: 'FILE_LIST_RECENT.XML',
      size: 0,
      lastUpdatedAt: (new Date()).toUTCString(),
      url: '',
    });
  }

  // Sort items by type
  items.sort((a: ItemsProps, b: ItemsProps) => {
    if( a.type < b.type ) return -1;
    if( a.type > b.type ) return 1;
    return 0;
  })

  // Set Columns
  Array.prototype.push.apply(columns, [
    {
      key: 'name',
      label: 'Name',
      render: (row: { name: string, url: string, type: string, directory: string, fileName: string }) => {
        switch(row.type) {
          case 'dir':
            return <NavLink className="file-link" to={row.url}>{row.name}</NavLink>
          case 'file': {
            let key = row.fileName;
            if (row.directory) {
              key = row.directory.concat(row.fileName);
            }

            return <LinkButton
                    text={row.fileName}
                    deliveryName={deliveryName}
                    path={key}
                  />
          }
        }
      }
    },
    {
      key: 'size',
      label: 'Size',
      render: (row: ItemsProps) => (row.type === 'file') ? formatBytes( row.size ) : null
    },
    {
      name: 'lastUpdatedAt',
      label: 'Last Updated',
      render: ( row: ItemsProps) => row.lastUpdatedAt? dateRender(row.lastUpdatedAt) : null
    }
  ]);

  return (
    <>
      { delivery.loading
        ? <Loader />
        : <Table
            columns={columns}
            data={items.map(item => {return { data: item }})}
            keyprop="name"
          />
      }
    </>
  );
};

export default withAuth0(FileView);