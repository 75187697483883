import React from 'react';
import { palette } from '@stats/playbook-components';
import styled from 'styled-components';

const StyledFooter = styled.footer`
  background-color: ${palette['sp-black']};
  padding: 24px 32px;
  color: #fff;
  text-align: right;
`;

const StyledCopyright = styled.small`
  font-family: Roboto, sans-serif;
  font-size: 14px;
`;

export const AppFooter: React.FC = () => {
  return (
    <StyledFooter>
      <StyledCopyright>
        &copy; {new Date().getFullYear()} Stats Perform. All rights reserved.
      </StyledCopyright>
    </StyledFooter>
  );
};
