import React from 'react';
import { withAuth0, WithAuth0Props, useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  font-size: 16px;
`;

type LinkButtonProps = WithAuth0Props & {
  text: string;
  deliveryName: string,
  path: string,
};

const LinkButton: React.FC<LinkButtonProps> = (props) => {
  const { text, deliveryName, path } = props;
  const { getAccessTokenSilently } = useAuth0();

  async function handleOnClick() {
    const token = await getAccessTokenSilently();

    const response = await fetch(
      `${process.env.REACT_APP_HTTP_GET_SIGNING_API_URL}/${deliveryName}/${encodeURIComponent(path)}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
    );

    const blob = await response.blob();
    const url = window.URL.createObjectURL(
      new Blob([blob]),
    );
    
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      text
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    // @ts-ignore
    link.parentNode.removeChild(link);
  }

  return (
    <StyledButton
      onClick={handleOnClick}
    >
      {text}
    </StyledButton>
  );
};

export default withAuth0(LinkButton);