import { useEffect, useState } from "react";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import { BrowserRouter } from "react-router-dom";
import Routes from "@components/Routes/Routes"
import { DeliveryFilesProps } from '@/propTypes';
import DeliveriesContext from '@/context/DeliveriesContext';
import "./App.css";

import styled from 'styled-components';

import AppHeader from '@components/Header';
import { AppFooter } from '@components/Footer';

const MainWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  [role='main'] {
    min-height: 100%;
    flex-grow: 1;
    margin: 24px 32px 32px;
  }
`;

const distributionServicesNamespace =
  "https://statsperform.com/distributionServices";

function App() {
  const { user, getAccessTokenSilently } = useAuth0();
  const [delivery, setDelivery] = useState<DeliveryFilesProps>({
    files: [],
    loading: true,
  });

  useEffect(() => {
    (async () => {
      try {
        if (user && user[distributionServicesNamespace]) {
          const token = await getAccessTokenSilently();

          const { deliveryName } = user[distributionServicesNamespace];

          const response = await fetch(
            `${process.env.REACT_APP_HTTP_GET_SIGNING_API_URL}/${deliveryName}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const data = await response.json();
          if (data) {
           setDelivery({ files: data, loading: false });
          }
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [getAccessTokenSilently, user]);

  

  return (
    <MainWrapper>
      <BrowserRouter>
        <AppHeader />
        <div role="main">
          <DeliveriesContext.Provider value={delivery}>
            <Routes />
          </DeliveriesContext.Provider>
        </div>
        <AppFooter />
      </BrowserRouter>
    </MainWrapper>
  );
}

export default withAuthenticationRequired(App);
