import React, { useCallback } from 'react';
import { palette } from '@stats/playbook-components';
import styled from 'styled-components';
import { NavLink, Link } from 'react-router-dom';
import logo from './logo.png';
import { useAuth0, withAuth0, WithAuth0Props } from '@auth0/auth0-react';

const StyledHeader = styled.header`
  font-family: Roboto, sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-item: flex-end;
  /* Pure black not in palette colors */
  background-color: #000;
  padding: 12px 32px;
  color: ${palette['sp-white']};
  position: sticky;
  top: 0;
  z-index: 10;
`;

const StyledH1 = styled.h1`
  font-size: 16px;
  font-weight: bold;
  // letter-spacing: 5.33px;
  vertical-align: baseline;
  margin: 0;
`;

const StyledLogo = styled.img`
  width: 122px;
  height: 44px;
  margin-bottom: -5px;
  margin-right: 17px;
  cursor: pointer;
`;

const StyledNav = styled.nav`
  display: flex;
  align-self: flex-end;
`;

const StyledNavLink = styled(NavLink)`
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  padding: 0 8px;
  position: relative;
  display: inline-block;
  color: ${palette['sp-white']};
  text-decoration: none;

  & + a {
    margin-left: 16px;
  }

  &:hover:after {
    content: '';
    display: block;
    position: absolute;
    background-image: linear-gradient(
      88deg,
      ${palette['kobe']} 0%,
      ${palette['jordan']} 36%,
      ${palette['ripkin']} 67%,
      ${palette['pele']} 100%
    );
    height: 4px;
    width: 100%;
    top: 100%;
    left: 0;
    margin-top: 8px;
  }
`;

const AppHeader: React.FC<WithAuth0Props> = (props) => {
  const { user } = props.auth0;
  const { logout } = useAuth0();

  const { deliveryName } = (user) ? user["https://statsperform.com/distributionServices"] : '';

  const handleLogout = useCallback(() => {
    logout({ logoutParams: { returnTo: window.location.origin }});
  }, []);

  return (
    <StyledHeader>
      <StyledH1>
        <Link to={`/${deliveryName}`}>
          <StyledLogo src={logo} alt="logo"/>
        </Link>
        HTTP Get Client
      </StyledH1>
      <StyledNav>
        <StyledNavLink to="/" onClick={handleLogout}>Logout</StyledNavLink>
      </StyledNav>
    </StyledHeader>
  );
};

export default withAuth0(AppHeader);