import React, { useState } from 'react';
import styled from 'styled-components';

import { Icon } from '@stats/playbook-components';

import TableCel from './TableCel';

const StyledToggleCel = styled.td`
  padding: 8px;
`;

interface RowProps {
  odd: boolean;
}

const StyledRow = styled.tr<RowProps>`
  background-color: ${(props) => (props.odd ? '#f2f2f2' : '#fff')};
`;

const StyledSubRow = styled.tr<RowProps>`
  background-color: ${(props) => (props.odd ? '#f2f2f2' : '#fff')};

  td:first-child {
    background-color: #fff;
  }

  td {
    padding: 3px 6px;
    vertical-align: middle;
    span {
      margin-top: 2px;
      display: inline-block;
    }
  }
`;

type TableColumnProps = {
  key: string;
  label?: string;
  render?: Function;
};

type TableRowProps = {
  rowkey: string;
  columns: Array<TableColumnProps>;
  rowdata: any;
  subdata?: Function;
  subdataRender?: Function;
  odd: boolean;
};

const TableRow: React.FC<TableRowProps> = (props) => {
  const { rowkey, columns, rowdata, subdata, odd, subdataRender } = props;

  type DeliveryInfo = {
    fileName: string;
    fileExtension: string;
    fileType: string;
  };

  const [subDataOpen, setSubDataOpen] = useState(false);
  const [subDataRows, setSubDataRows] = useState<Array<DeliveryInfo>>([]);

  return (
    <>
      <StyledRow odd={odd}>
        {subdataRender && (
          <StyledToggleCel key="expand">
            {subdata && (
              <Icon
                size="small"
                variant={subDataOpen ? 'chevron-up' : 'chevron-down'}
                onClick={() => {
                  subdata().then((rows: Array<DeliveryInfo>) => {
                    setSubDataRows(rows);
                  });
                  setSubDataOpen(!subDataOpen);
                }}
              />
            )}
          </StyledToggleCel>
        )}
        {columns.map((column) => (
          <TableCel
            key={rowkey + column.key}
            data={rowdata}
            render={column.render}
          />
        ))}
      </StyledRow>

      {subdata && subDataOpen && (
        <>
          {subDataRows.map((row, idx) => (
            <StyledSubRow odd={idx % 2 === (odd ? 1 : 0)} key={idx}>
              <TableCel data="&nbsp;" />
              <TableCel
                colspan={columns.length}
                data={row}
                render={subdataRender}
              />
            </StyledSubRow>
          ))}
        </>
      )}
    </>
  );
};

export default TableRow;
