import React from 'react';
import { Redirect, Route, Switch} from "react-router-dom";
import { withAuth0, WithAuth0Props } from '@auth0/auth0-react';

import FileView from '@/pages/FileView';
import { ErrorPage } from '@/pages/ErrorPage';

const Routes: React.FC<WithAuth0Props> = (props) => {
  const { user } = props.auth0;

  if (!user) {
    return (
      <Switch>
        <Route component={ErrorPage} />
      </Switch>
    )
  }

  const { deliveryName } = user["https://statsperform.com/distributionServices"];

  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={`/${deliveryName}`} />
      </Route>
      <Route path={[`/${deliveryName}/:deliveryDir*`, `/${deliveryName}`]} component={FileView} />
      <Route component={ErrorPage} />

    </Switch>
  )
}

export default withAuth0(Routes);